/* What is bio */
.what-is-bio-energy-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.what-is-bio-energy-text {
  flex: 2;
  text-align: center;
  padding: 10px;
}

.what-is-bio-energy-image {
  flex: 1;
}

.what-is-bio-energy-image img {
  width: 90%;
  height: auto;
  transform: rotate(10deg);
}

.what-is-bio-energy-h2 {
  color: #98d829;
  padding: 10px;
  font-size: 5rem;
}

/* Benefits */
.benefits-section-bio {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.benefits-h2-bio {
  color: #00d2fc;
  font-size: 3rem;
}

.benefits-paragraph-bio {
  color: #ccc;
  font-size: 1rem;
}

.benefits-cards-container-bio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.benefits-card-bio {
  background-color: #333;
  border: 2px solid #58bf69;
  border-radius: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  flex: 1 1 calc(40% - 40px);
}

.card-inner-bio {
  display: flex;
}

.card-image-bio {
  flex: 1;
}

.card-image-bio img {
  width: 100%;
  height: auto;
}

.card-content-bio {
  flex: 1;
  padding-left: 20px;
  /* Add padding to separate image and content */
}

.benefits-h3-bio {
  color: #13d56e;
  font-size: 1.75rem;
}

.benefits-paragraph-bio {
  color: #ccc;
}

@media (max-width: 768px) {
  .benefits-card-bio {
    flex-basis: calc(50% - 40px);
    /* 2 cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .benefits-card-bio {
    flex-basis: calc(100% - 40px);
    /* 1 card per row on mobile screens */
  }

  /* Specific styles for one card per row */
  .benefits-card-bio {
    flex-direction: column;
    /* Stack content vertically */
  }

  .card-content-bio {
    padding-top: 20px;
    /* Add padding to separate image and content */
  }
}

/* Limitations and Short Comings */
.container-bio {
  display: flex;
  flex-direction: column;
  background-color: #395b80;
  color: #fff;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.tabs-bio {
  display: flex;
}

.tab-bio {
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #555;
  /* Darker background for tabs */
}

.tab.active-bio {
  background-color: #209557;
  color: #000000;
}

.limitations-section-bio {
  margin-top: 20px;
}

.limitation-card-bio {
  display: flex;
  flex-direction: row;
  background-color: #000000;
  /* Card background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
}

.limitation-card-bio .limitation-content-bio {
  flex: 1;
}

.limitation-card-bio img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-left: 20px;
  /* Add some spacing between image and content */
}

.limitations-h2-bio {
  margin-top: 0;
  margin-left: 20px;
}

.limitations-paragraph-bio {
  margin-bottom: 0;
  margin-left: 20px;
}

.limitations-image-bio {
  height: 25%;
  width: auto;
}

/* How bio Works */
.callout-box-bio {
  background-color: #116a4b;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callout-content-bio {
  display: flex;
}

.text-content-bio {
  flex: 3;
  /* 3/4 of the width */
  padding-right: 20px;
  /* Adjust spacing between text and image */
}

.image-content-bio {
  flex: 1;
}

.image-style-bio {
  width: 80%;
  border-radius: 4px;
}

.callout-title-bio {
  font-size: 36px;
  margin-bottom: 10px;
  color: #98d829;
}

.callout-text-bio {
  font-size: 18px;
  line-height: 1.6;
  color: #ffffff;
}

.latex-bio {
  filter: invert(100%);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  /* Adjust the gap between images as needed */
}

.image-content-solar {
  width: 100%;
}

.image-style-solar {
  width: 100%;
  height: auto;
  max-width: 100%;
  /* Ensure images don't exceed their container */
}

/* Archive */
/* .geo-hero-banner {
    background-image: url('https://wallpapers.com/images/hd/bio-panels-with-clouds-reflection-x5v5fupz5yk9owxc.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
}

.bio-hero-banner {
    background-image: url('./../images/BioHero.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
} */

/* What is Solar */
.what-is-solar-energy-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.what-is-solar-energy-text {
  flex: 2;
  text-align: center;
  padding: 10px;
}

.what-is-solar-energy-image {
  flex: 1;
}

.what-is-solar-energy-image img {
  width: 90%;
  height: auto;
  transform: rotate(10deg);
}

.what-is-solar-energy-h2 {
  color: #f3d03e;
  padding: 10px;
  font-size: 5rem;
}

/* Benefits */
.benefits-section-solar {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.benefits-h2-solar {
  color: #618baa;
  font-size: 3rem;
}

.benefits-paragraph-solar {
  color: #ccc;
  font-size: 1rem;
}

.benefits-cards-container-solar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.benefits-card-solar {
  background-color: #333;
  border: 2px solid #ffdb4d;
  border-radius: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  flex: 1 1 calc(40% - 40px);
}

.card-inner-solar {
  display: flex;
}

.card-image-solar {
  flex: 1;
}

.card-image-solar img {
  width: 100%;
  height: auto;
}

.card-content-solar {
  flex: 1;
  padding-left: 20px;
}

.benefits-h3-solar {
  color: #ffdb4d;
}

.benefits-paragraph-solar {
  color: #ccc;
}

@media (max-width: 768px) {
  .benefits-card-solar {
    flex-basis: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .benefits-card-solar {
    flex-basis: calc(100% - 40px);
  }

  .benefits-card-solar {
    flex-direction: column;
  }

  .card-content-solar {
    padding-top: 20px;
  }
}

/* Limitations and Short Comings */
.container-solar {
  display: flex;
  flex-direction: column;
  background-color: #395b80;
  color: #fff;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.tabs-solar {
  display: flex;
}

.tab-solar {
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #555;
}

.tab.active-solar {
  background-color: #209557;
  color: #000000;
}

.limitations-section-solar {
  margin-top: 20px;
}

.limitation-card-solar .limitation-content-solar {
  display: flex;
  flex-direction: row;
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.limitation-card-solar {
  flex: 1;
}

.limitation-card-solar img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-left: 20px;
}

.limitations-h2-solar {
  margin-top: 0;
  margin-left: 20px;
}

.limitations-paragraph-solar {
  margin-bottom: 0;
  margin-left: 20px;
}

.limitations-image-solar {
  width: 18%;
  height: auto;
}

/* How Solar Works */
.callout-box-solar {
  background-color: #5b4924;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callout-content-solar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Left-align text */
}

.callout-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.callout-title-solar {
  font-size: 36px;
  margin-bottom: 10px;
  color: #ffc85e;
  align-self: center;
}

.explanation {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
}

.explanation p {
  flex: 5;
  margin-right: 20px;
  /* Adjust as needed */
  text-align: left;
  size: 2.5rem;
}

.explanation img {
  flex: 1;
  max-width: 60%;
  height: auto;
  align-self: flex-end;
}

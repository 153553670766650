#home-title {
  width: 42.5vw;
  height: 20vw;
  margin-left: 10vw;
  margin-top: calc(50vh - 10vw);
  font-size: 10vw;
  line-height: 10vw;
  text-wrap: wrap;
  text-shadow: 0 0 15px black;
}

#home-hero-background {
  width: 75%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 25%;
  z-index: -1;
}

#home-content {
  margin-top: calc(50vh - 10vw);
}

.home-text {
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: gainsboro;
  font-family: "DM Sans";
  font-size: 18px;
}

.home-header {
  margin-bottom: 0;
  font-size: 64px;
  text-align: center;
  text-shadow: 0 0 10px white;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: white;
  color: transparent;
}

.home-text-glow {
  text-shadow: 0 0 10px white;
}

.home-subheader {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  text-align: center;
  color: whitesmoke;
}

.home-content-card {
  width: 60%;
  margin-left: 20%;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: rgb(30, 30, 30);
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 10px;
}

.home-graph {
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  border-radius: 12px;
}

.home-list {
  width: 60%;
  margin-left: calc(20% - 20px);
}

.home-bullet {
  position: relative;
  left: -20px;
}

#home-carousel {
  margin-top: 20px;
  position: relative;
}

.home-carousel-card {
  width: calc(50vw - 10px);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgb(30, 30, 30);
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 10px;
  display: inline-block;
  transition: 0.75s;
}

.home-carousel-card-center {
  left: calc(50% - 25vw);
  z-index: 1;
}

.home-carousel-card-center-right {
  animation-name: carousel-animation-center-right;
  animation-duration: 0.75s;
}

.home-carousel-card-center-left {
  animation-name: carousel-animation-center-left;
  animation-duration: 0.75s;
}

.home-carousel-card-left {
  position: absolute;
  left: 50vw;
  transform: scale(0.5);
  animation-name: carousel-animation-left;
  animation-duration: 0.75s;
}

.home-carousel-card-left:hover {
  cursor: pointer;
}

.home-carousel-card-right {
  position: absolute;
  right: 50vw;
  transform: scale(0.5);
  animation-name: carousel-animation-right;
  animation-duration: 0.75s;
}

.home-carousel-card-right:hover {
  cursor: pointer;
}

.home-carousel-card-invisible {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  z-index: -1;
}

.home-carousel-card-title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.home-carousel-card-image {
  width: 80%;
  aspect-ratio: 2;
  margin-left: 10%;
  background-color: pink;
}

.home-carousel-card-header {
  margin-bottom: 0;
  text-align: center;
  font-size: 24px;
  text-decoration: underline;
}

.home-carousel-card-text {
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

#home-map {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 24px;
}

#home-map-image {
  width: 50%;
  aspect-ratio: 1.75;
  margin-left: 25%;
  margin-bottom: 35px;
  border-style: solid;
  border-width: 5px;
  border-color: dimgray;
}

#home-map-button {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: calc(50% - 130px);
  font-family: "DM Sans";
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  color: black;
  background-color: white;
  border-radius: 10px;
}

#home-map-button:hover {
  background-color: darkgray;
}

#home-spacing {
  height: 50px;
}

@media (max-width: 768px) {
  #home-title {
    width: 275px;
    height: 128px;
    margin-left: calc(((100% - 340px) / 7) + 20px);
    margin-top: calc(50vh - 64px);
    font-size: 64px;
    line-height: 64px;
  }

  #home-hero-background {
    width: 100vw;
    left: 0;
  }

  .home-content-card {
    width: 80%;
    margin-left: 10%;
  }

  .home-carousel-card {
    width: 70vw;
  }

  .home-carousel-card-center {
    left: calc(50% - 35vw - 20px);
  }

  .home-carousel-card-center-right {
    animation-name: carousel-animation-center-right-mobile;
  }

  .home-carousel-card-center-left {
    animation-name: carousel-animation-center-left-mobile;
  }

  #home-map-image {
    width: 80%;
    margin-left: 10%;
  }
}

@keyframes carousel-animation-center-right {
  from {
    right: 0;
  }

  to {
    right: auto;
  }
}

@keyframes carousel-animation-center-left {
  from {
    left: 0;
  }

  to {
    left: calc(50% - 25vw);
  }
}

@keyframes carousel-animation-right {
  from {
    right: 25vw;
    transform: none;
  }

  to {
    right: 50vw;
    transform: scale(0.5);
  }
}

@keyframes carousel-animation-left {
  from {
    left: 25vw;
    transform: none;
  }

  to {
    left: 50vw;
    transform: scale(0.5);
  }
}

@keyframes carousel-animation-center-right-mobile {
  from {
    right: 0;
  }

  to {
    right: auto;
  }
}

@keyframes carousel-animation-center-left-mobile {
  from {
    left: -30vw;
  }

  to {
    left: calc(50% - 35vw - 20px);
  }
}

#layout-background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0;
  animation-name: background-animation;
  animation-duration: 1s;
  z-index: -1;
}

#layout-navbar {
  width: 755px;
  height: 60px;
  margin-left: calc(50vw - 379.5px);
  position: fixed;
  top: 15px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  animation-name: navbar-animation;
  animation-duration: 1s;
  z-index: 10;
}

#layout-navbar-selector {
  width: 120px;
  height: 50px;
  margin-top: 5px;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  z-index: -1;
  transition-duration: 0.5s;
}

.layout-navbar-link {
  width: 120px;
  height: 35px;
  margin-left: 5px;
  margin-top: 5px;
  padding-top: 15px;
  display: inline-block;
  font-family: "DM Sans";
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition-duration: 0.5s;
}

.layout-navbar-selected {
  z-index: 1;
  color: black;
}

.layout-navbar-selectable:hover {
  background-color: #303030;
  cursor: pointer;
}

#layout-dropdown {
  width: calc(100vw - 40px);
  margin-left: 10px;
  padding-bottom: 5px;
  position: fixed;
  top: 15px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  animation-name: navbar-animation;
  animation-duration: 1s;
  transition: 0.5s;
  z-index: 10;
}

#layout-dropdown-selector {
  width: calc(100% - 10px);
  height: 50px;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  z-index: -1;
  transition-duration: 0.5s;
}

#layout-dropdown-selector:hover {
  cursor: pointer;
}

.layout-dropdown-link {
  width: calc(100% - 10px);
  height: 35px;
  margin-left: 5px;
  margin-top: 5px;
  padding-top: 15px;
  position: absolute;
  display: block;
  font-family: "DM Sans";
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition-duration: 0.5s;
}

#layout-footer-divider {
  width: calc(100% - 40px);
  height: 2px;
  margin-left: 20px;
  background-color: white;
}

.layout-footer {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

@keyframes background-animation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes navbar-animation {
  from {
    top: -100px;
  }

  to {
    opacity: 15px;
  }
}

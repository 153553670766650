/* What is geothermal */
.what-is-geothermal-energy-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.what-is-geothermal-energy-text {
  flex: 2;
  text-align: center;
  padding: 10px;
}

.what-is-geothermal-energy-image {
  flex: 1;
}

.what-is-geothermal-energy-image img {
  width: 100%;
  height: auto;
  transform: rotate(0deg);
}

.what-is-geothermal-energy-h2 {
  color: #98d829;
  padding: 10px;
  font-size: 5rem;
}

/* Benfits */
.benefits-section {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.benefits-h2 {
  color: #f87b1f;
  font-size: 3rem;
}

.benefits-paragraph {
  color: #ccc;
  font-size: 1rem;
}

.benefits-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.benefits-card {
  background-color: #333;
  border: 3px solid #58bf69;
  /* geothermal energy yellow */
  border-radius: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  flex: 1 1 calc(40% - 40px);
}

.card-inner {
  display: flex;
}

.card-image {
  flex: 1;
}

.card-image img {
  width: 100%;
  height: auto;
}

.card-content {
  flex: 1;
  padding-left: 20px;
  /* Add padding to separate image and content */
}

.benefits-h3 {
  color: #7fc44f;
  font-size: 1.75rem;
}

.benefits-paragraph {
  color: #ccc;
}

@media (max-width: 768px) {
  .benefits-card {
    flex-basis: calc(50% - 40px);
    /* 2 cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .benefits-card {
    flex-basis: calc(100% - 40px);
    /* 1 card per row on mobile screens */
  }

  /* Specific styles for one card per row */
  .benefits-card {
    flex-direction: column;
    /* Stack content vertically */
  }

  .card-content {
    padding-top: 20px;
    /* Add padding to separate image and content */
  }
}

/* Limitations and Short Comings */
.container {
  display: flex;
  flex-direction: column;
  background-color: #395b80;
  color: #fff;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.tabs {
  display: flex;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #555;
  /* Darker background for tabs */
}

.tab.active {
  background-color: #209557;
  color: #000000;
}

.limitations-section {
  margin-top: 20px;
}

.limitation-card {
  display: flex;
  flex-direction: row;
  background-color: #000000;
  /* Card background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Shadow effect */
}

.limitation-card .limitation-content {
  flex: 1;
}

.limitation-card img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-left: 20px;
  /* Add some spacing between image and content */
}

.limitations-h2 {
  margin-top: 0;
  margin-left: 20px;
}

.limitations-paragraph {
  margin-bottom: 0;
  margin-left: 20px;
}

.limitations-image {
  width: 18%;
  height: auto;
}

/* How geothermal Works */
.callout-box {
  background-color: #754c29;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callout-content {
  display: flex;
}

.text-content {
  flex: 2;
  /* 3/4 of the width */
  padding-right: 20px;
  /* Adjust spacing between text and image */
}

.image-content {
  flex: 1;
  align-content: center;
}

.image-style {
  width: 100%;
  border-radius: 4px;
}

.callout-title {
  font-size: 36px;
  margin-bottom: 10px;
  color: #ffffff;
}

.callout-text {
  font-size: 18px;
  line-height: 1.6;
  color: #ffffff;
}

.latex {
  filter: invert(100%);
}

/* Archive */
/* .geo-hero-banner {
    background-image: url('https://wallpapers.com/images/hd/geothermal-panels-with-clouds-reflection-x5v5fupz5yk9owxc.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
}

.geothermal-hero-banner {
    background-image: url('./../images/geothermalHero.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
} */

#error-title {
  margin-top: 125px;
  margin-bottom: 0;
  font-size: 64px;
  text-align: center;
}

#error-subtitle {
  margin-top: 0;
  margin-bottom: 50px;
  font-size: 24px;
  text-align: center;
}
